//--------------------------------------------------
//Rendered Component when opening website for the first time
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { ProjectModal } from '../GeneralComponents';

import otherBubble from '../../Images/fun-small-thing.svg'
import leftButton from '../../Images/left-button.svg'
import rightButton from '../../Images/right-button.svg'
import TVWH from '../../Images/TVWH.PNG'
import FATE from '../../Images/FATE.png'
import Agent13 from '../../Images/Agent13.PNG'
import MINUS from '../../Images/MINUS.png'
import resume from '../../Files/JC_Ho_Resume.pdf'

// TEMPLATE
// { name: '', img: blank, description: '', role: '', date: '' },

let projects = [
    { name: 'Treasure Valley Water Hub', img: TVWH, description: 'A React website created for Immerse.', role: 'Web Developer', date: '8/15/24'},
    { name: 'F.A.T.E.', img: FATE, description: "A React website created for teachers to rate other academic professional's portfolios.", role: 'Temporary Web Developer', date: '6/6/24' },
    { name: 'Agent 13', img: Agent13, description: 'A Unity VR Action/Stealth game.', role: 'Game Developer', date: '5/3/24' },
    { name: 'ULT MINUS', img: MINUS, description: 'A casual competitive mod created by me for Smash Ultimate.', role: 'Dev Lead', date: 'WIP' },
]

const Home = ({ aboutRef, projectRef, resumeRef }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState({ name: '', image: null });

    const handleSlideClick = (project) => {
        setSelectedProject(project);
        setShowModal(true);
      };
    
      const handleClose = () => {
        setShowModal(false);
      };

    return (
        <Fragment>
            <div className='home'>
                <div className='about-section' ref={aboutRef}>
                    <div className='text-bubble'>
                        <h1>About Me</h1>
                        <p>
                        Hello! I am JC Ho! I am a Senior at Boise State University currently enrolled in the Games, Interactive Media, and Mobile program/major. My skills are mainly in Web Development. I have made a few projects in React and hope to continue growing my Web Development skills. I also have developed a few games and also have made my own mods. I always look for chances to improve myself and my portfolio, so I hope to see this website grow as I do!
                        </p>
                    </div>
                    <img className='bubble1' src={otherBubble}></img>
                    <img className='bubble2' src={otherBubble}></img>
                </div>

                <div className='project-section' ref={projectRef}>
                    <h1>Projects</h1>
                    <Swiper 
                        className='project-swiper'
                        modules={[Navigation]}
                        navigation={{
                            nextEl: '.next',
                            prevEl: '.prev',
                        }} 
                        spaceBetween={50}
                        slidesPerView={2}>

                        {projects.map((project, index) => (
                            <SwiperSlide key={index} className='slide' onClick={() => handleSlideClick(project)}>
                            <h1 className='project-title'>{project.name}</h1>
                            <img src={project.img} />
                            <p className='description'>{project.description}</p>
                            <div className='extra-details'>
                                <p>Role: {project.role}</p>
                                <p>Date Completed: {project.date}</p>
                            </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <div className='swiper-buttons'>
                        <img className="prev" src={leftButton}></img>
                        <img className="next" src={rightButton}></img>
                    </div>

                    {showModal && (
                        <ProjectModal
                        name={selectedProject.name}
                        image={selectedProject.img}
                        show={showModal}
                        handleClose={handleClose}
                        />
                    )}
                </div>

                <div className='resume-section' ref={resumeRef}>
                    <h1>Resume</h1>
                    <iframe class='resume' src={resume}></iframe>
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Home);
export { connection as Home };