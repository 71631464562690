//--------------------------------------------------
//Modal popup for projects
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

// TEMPLATE
// { name: '', description: ''},

let descriptions = [
    { name: 'Treasure Valley Water Hub', description: ''},
    { name: 'F.A.T.E.', description: ''},
    { name: 'Agent 13', description: ''},
    { name: 'ULT MINUS', description: ''},
]

const ProjectModal = ({ name, image, show, handleClose }) => {
    return (
        <Fragment>
            <Modal className='project-modal' show={show} onHide={handleClose}>
                <Modal.Dialog className='project-modal-dialog'>
                    <Modal.Header className='project-modal-header' closeButton>
                        <Modal.Title className='project-modal-title'>{name}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className='project-modal-body'>
                        <img src={image}></img>
                        <p>Modal body text goes here.</p>
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(ProjectModal);
export { connection as ProjectModal };