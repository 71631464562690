//--------------------------------------------------
//Navbar for the application
//--------------------------------------------------

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faSquareFacebook, faGithub } from '@fortawesome/free-brands-svg-icons';

import me from '../../Images/Me.JPG'

const NavigationBar = ({ scrollToRef, aboutRef, projectRef, resumeRef }) => {
    return (
        <Fragment>
            <div className='side-bar'>
                <div className='side-bar-components'>
                    <img src={me}></img>
                    <h1>JC Ho</h1>
                    <h3>Web Developer | Game Developer</h3>
                    <hr/>
                    <h2 onClick={() => scrollToRef(aboutRef)}>About Me</h2>
                    <h2 onClick={() => scrollToRef(projectRef)}>Projects</h2>
                    <h2 onClick={() => scrollToRef(resumeRef)}>Resume</h2>
                    <hr/>
                    <h4>Contact Me</h4>
                    <p>(208) 507-8846</p>
                    <a href= "mailto: leiadog11@gmail.com">leiadog11@gmail.com</a>
                    <div className='icons'>
                        <Link to='https://www.linkedin.com/in/jcrho/' target='_blank'>
                            <span><FontAwesomeIcon icon={faLinkedin} /></span>
                        </Link>
                        <Link to='https://www.facebook.com/heck.heck.334/' target='_blank'>
                            <span><FontAwesomeIcon icon={faSquareFacebook} /></span>
                        </Link>
                        <Link to='https://github.com/leiadog11' target='_blank'>
                            <span><FontAwesomeIcon icon={faGithub} /></span>
                        </Link>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {

};

const connection = connect(mapState, actionCreators)(NavigationBar);
export { connection as NavigationBar };